<template>
    <div class="all-height d-flex justify-center">
      <div class="all-width">
        <v-card class="shadow-off" loader-height="2" :loading="loading" :disabled="loading">
          <v-card-text>
            <div class="d-flex align-center justify-space-between">
                <div class="">
                    <div class="d-flex">
                        <p class="mb-1">Home</p>
                        <p class="px-2 mb-1">/</p>
                        <p class="mb-1">{{ metadata.description }}</p>
                    </div>
                    <div class="d-flex">
                        <p class="black--text font-weight-bold text-h5">Users</p>
                        <v-btn class="shadow-off ml-2" small icon
                            @click="refreshData()"><v-icon>mdi-refresh</v-icon></v-btn>
                    </div>
                </div>
                <v-btn small color="black" class="white--text" height="4vh" v-if="($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add') || $nova.hasRight(right, 'delete'))"  @click="associateUserDialogBox()"><v-icon class="mr-1">mdi-plus</v-icon>User</v-btn>

            </div>

            <div v-if="loading && !metadata._id">
              <v-skeleton-loader
                type="table-heading, list-item-two-line, article, article"
              ></v-skeleton-loader>
            </div>
            <div class="" v-else-if="metadata._id">
              <div class="mb-4">

                <lb-datatableaction v-if="finaluserlist.length>0" :headers="headers" :tabledata="finaluserlist"
                    :itemsperpage="itemsPerPage" searchterm="" :bulkActions="false">
    
                    <template v-slot:tbody>
                        <tr v-for="item in finaluserlist" :key="item._id" class=" "
                            :class="{ 'low-opacity': item.status === false }">
                            <template v-for="header in headers">
                                <td v-if="header.visible && header.value !== 'action'" :key="header.value"
                                    class="px-2 single-line"
                                    :class="{ 'description-header': header.value === 'description', 'single-line': header.value !== 'description' }">
                                    <div class="" v-if="header.value==='progress'">
                                        <div v-if="(((userrolecount[item._id] || {}).preparer || 0) + ((userrolecount[item._id] || {}).approver || 0)) > 0" style="max-width:100px;margin:auto">
                                            {{ (((((userrolecount[item._id] || {}).completed || 0)/(((userrolecount[item._id] || {}).preparer || 0) + ((userrolecount[item._id] || {}).approver || 0))) || 0)*100).toFixed(0) }}%
                                            <v-progress-linear
                                              :height="10" color="green"
                                              :value="Math.max(((((userrolecount[item._id] || {}).completed || 0)/(((userrolecount[item._id] || {}).preparer || 0) + ((userrolecount[item._id] || {}).approver || 0))) || 0)*100,2)"
                                            >
                                            </v-progress-linear>
                                          </div>
                                    </div>
                                    <div class="" v-else-if="header.value==='gls'">
                                        <v-tooltip bottom content-class="tooltip-bottom" v-if="(userrolecount[item._id] || {}).preparer">
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-chip label small color="lightblue" class="blue--text mr-1" v-bind="attrs" v-on="on">{{userrolecount[item._id].preparer}}</v-chip>
                                            </template>
                                            <span>Preparer</span>
                                          </v-tooltip>
                                          <v-tooltip bottom content-class="tooltip-bottom" v-if="(userrolecount[item._id] || {}).approver">
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-chip label small color="lightyellow" class="yellow--text mr-1" v-bind="attrs" v-on="on">{{userrolecount[item._id].approver}}</v-chip>
                                            </template>
                                            <span>Approver</span>
                                          </v-tooltip>
                                    </div>
                                    <div class="" v-else-if="header.value==='type'">
                                        <v-chip small label v-if="item.type === 'manager'" color="lightpurple" class="caccounting--text">Manager</v-chip>
                                        <v-chip small label v-else-if="item.type === 'general'" color="lightyellow" class="yellow--text">General</v-chip>
                                        <v-chip small label v-else color="lightred" class="error--text">Unknown</v-chip>

                                    </div>

                                <span v-else>{{ item[header.value] }}</span>
                                </td>
                            </template>
                            <td class="d-flex align-center px-3 single-line border-right border-left action-data" v-if="($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add') || $nova.hasRight(right, 'delete'))">
                                <v-tooltip bottom content-class="tooltip-bottom" v-if="item._id!==userid">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn small icon v-bind="attrs" v-on="on" @click="manageUser('move', item.type, item._id)"><v-icon>mdi-sync</v-icon></v-btn>
                                    </template>
                                    <span v-if="item.type === 'general'">Change to Manager User</span>
                                    <span v-else>Change to General User</span>
                                  </v-tooltip>
                                  <v-tooltip bottom content-class="tooltip-bottom" v-if="item._id!==userid">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn small icon v-bind="attrs" v-on="on" @click="deleteUserDialogBox(item)"><v-icon>mdi-close</v-icon></v-btn>
                                    </template>
                                    <span>Remove User</span>
                                  </v-tooltip>                          
                                  <v-tooltip bottom content-class="tooltip-bottom">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn small icon v-bind="attrs" v-on="on" @click="replaceUserDialog(item._id, item.type)"><v-icon>mdi-account-convert</v-icon></v-btn>
                                    </template>
                                    <span>Replace User</span>
                                  </v-tooltip>  
                            </td>
                        </tr>
                    </template>
                </lb-datatableaction>
                <div v-else class="subtitle-1 grey--text pa-4">No Data</div>
              </div>
            </div>
            <div v-if="!metadata._id" class="grey--text title text-center pa-8">
              No Users found
            </div>
  
          </v-card-text>
        </v-card>
  
        <lb-drawer v-model="associateuserdialog" heading="Add User" width="400" :loading="associateuserloading">
          <template v-slot:body>
            <div>
              <v-row>
                <v-col cols="12" class="my-0 py-1">
                    <p class="ma-0 mb-1">User*</p>
                  <lb-dropdown label="" placeholder="Select User name" v-model="associateuserdata.userid" :items="associateuserlist" itemtext="name"
                    itemvalue="_id" />
                </v-col>
                <v-col cols="12" class="my-0 py-1">
                    <p class="ma-0 mb-1">Type*</p>
                  <lb-dropdown v-model="associateuserdata.type" label="" placeholder="Select User type" :items="usertypes" itemtext="name"
                    itemvalue="value" />
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:actions>
            <span class="caption">*Required</span>
            <v-spacer></v-spacer>
            <v-btn color="black" class="white--text" height="4vh" small @click="manageUser('associate')" :disabled="!associateuserdata.type || !associateuserdata.userid">Add</v-btn>
          </template>
        </lb-drawer>
    
        <lb-drawer v-model="replaceuserdialog" heading="Replace User" width="500" :loading="replaceuserloading">
          <template v-slot:body>
            <div>
              <div class="mb-3">
                <span class="font-weight-bold mr-2">From User:</span>
                <span class="">{{(userdata[replaceuserid] || {}).displayname || 'Unknown'}}</span>
              </div>
              <p class="ma-0 mb-1">To User*</p>
              <lb-dropdown label="" placeholder="Select the user your want to replace with" v-model="replaceuserdata.userid" :items="replaceuserlist" itemtext="displayname" itemvalue="_id" />
              <div v-if="(userrolecount[replaceuserid] || {}).preparer || (userrolecount[replaceuserid] || {}).approver">
                <div>Since the user is also associated to various GL items, all those association will be replaced with the new user.</div>
                <div><span class="font-weight-bold mr-2">Note:</span>If the new user is already assigned to the GL item in a different capacity, then they will not be re-assigned.</div>
                <div class="font-weight-bold">Do you want to proceed?</div>
              </div>
            </div>
          </template>
          <template v-slot:actions>
            <span class="caption">*Required</span>
            <v-spacer></v-spacer>
            <v-btn color="black" class="white--text" height="4vh" small @click="replaceUser()" :disabled="!replaceuserdata.userid">Replace</v-btn>
          </template>
        </lb-drawer>
  
        <lb-drawer v-model="deleteuserdialog" heading="Remove User" width="500" :loading="deleteuserloading">
          <template v-slot:body>
            <div>
              <div class="mb-3">
                <span class="font-weight-bold mr-2">Selected User:</span>
                <span class="">{{(deleteuserdata || {}).displayname || 'Unknown'}}</span>
              </div>
              <div v-if="(userrolecount[deleteuserdata._id] || {}).preparer || (userrolecount[deleteuserdata._id] || {}).approver">
                Since the user is also associated to various GL items, all those association will be removed if the user is removed from the project.
              </div>
              <div v-else>This will remove the user from the current project.</div>
              <div class="font-weight-bold">Do you want to proceed?</div>
            </div>
          </template>
          <template v-slot:actions>
            <v-spacer></v-spacer>
            <v-btn color="black" class="white--text" height="4vh" small @click="manageUser('remove', deleteuserdata.type, deleteuserdata._id)()">Remove</v-btn>
          </template>
        </lb-drawer>
  
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'financialclose_project_users',
    // props: ['props'],
    data: function(){
      return {
        right:"financialclose_project",
        metadata: {},
        userrolecount: {},
        userid: "",
        formdata: {},
        userdata: {},
        stage: -1,      
        id: "",
        loading: false,
  
        associateuserdialog: false,
        associateuserloading: false,
        associateuserdata: {},
        associateuserlist: [],
        userlist: [],
        managerlist: [],
        infoowners: [],
        possibleinfoowners: [],
        replaceuserdialog: false,
        replaceuserloading: false,
        replaceuserid: "",
        replaceuserdata: {},
        replaceuserlist: [],
        deleteuserdialog: false,
        deleteuserloading: false,
        deleteuserdata: {},
        usertypes: [
          { name: "General user", value: "general" },
          { name: "Manager user", value: "manager" }
        ],
        headers:[
            {
                value:'name',
                text:"Name",
                sortable:false,
                filterable:false,
                visible:true
            },
            {
                value:'email',
                text:"Email",
                sortable:false,
                filterable:false,
                visible:true
            },
            {
                value:'type',
                text:"Type",
                sortable:false,
                filterable:false,
                visible:true
            },
            {
                value:'gls',
                text:"Associated GLs",
                sortable:false,
                filterable:false,
                visible:true
            },

            {
                value:'progress',
                text:"Progress",
                sortable:false,
                filterable:false,
                visible:true
            },
            {
                value:'action',
                text:"Action",
                sortable:false,
                filterable:false,
                visible:true
            },
        ],
        itemsPerPage:16
      }
    },
    props: {
      project: {
        type: String,
        default: ""
      },
      embed: {
        type: Boolean,
        default: false
      },
    },
    components: {
    },
    created() {
      this.refreshData();
    },
    activated() {
      this.refreshData();
    },
    methods: {
      refreshData() {
        this.id = this.project || this.$route.params.id || "";
        this.userid = this.$store.state.user.id;
        this.getData();
      },
      getData() {
        this.loading = true;
       
        return this.axios.post("/v2/fluxmanagement/analysis/getuserdetails/"+this.id).then(dt => {
          if(dt.data.status === "success" && dt.data.data.length > 0){
            let data = dt.data.data[0] || {}
            this.metadata = data.metadata || {};
            this.userlist = [];
            this.userlist = this.metadata.userlist || [];
            this.managerlist = [];
            this.managerlist = this.metadata.managerlist || [];
            this.stage = this.metadata.stage;
            this.userrolecount = data.userrolecount || {};
          }else throw Error("Error loading users");
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).then(() => {
          return this.axios.post("/v2/fluxmanagement/analysis/getusersformdata");
        }).then(dt => {
          if(dt.data.status === "success"){
            this.formdata = dt.data.data[0] || {};
            this.userdata = {};
            let colors = this.$nova.getColors((this.formdata.users || []).length, 0.2);
            // console.log(colors);
            for (let i = 0; i < (this.formdata.users || []).length; i++) {
              const el = (this.formdata.users || [])[i];
              this.userdata[el._id] = el;
              this.userdata[el._id].color = colors[i];            
            }
          }else throw new Error(dt.data.message);
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
        }).finally(() => {
          this.loading = false;
        })
      },
      deleteUserDialogBox(data) {
        this.deleteuserdata = {...data};
        console.log("qwertyuk",data);
        
        this.deleteuserdialog = true;
      },
      associateUserDialogBox() {
        this.associateuserdata = {};
        this.associateuserlist = [];
        for (const k in this.formdata.users) {
          if (Object.hasOwnProperty.call(this.formdata.users, k)) {
            const el = this.formdata.users[k];
            if(this.managerlist.indexOf(el._id) === -1 && this.userlist.indexOf(el._id) === -1) this.associateuserlist.push(el);
          }
        }
        this.associateuserdialog = true;
      },
      manageUser(type, usertype='general', uid="") {
        let data = {}
        if(type === "associate") {
          this.associateuserloading = true;
          data = {userid: this.associateuserdata.userid, usertype: this.associateuserdata.type, type: "associate"};
        }
        else if(type === "move") {
          this.loading = true;
          data = {userid: uid, usertype: usertype, type: "move"};
        }
        else if(type === "remove") {
          this.deleteuserloading = true;
          data = {userid: uid, usertype: usertype, type: "remove"};
        }
        let successflag = false;
        this.axios.post("/v2/fluxmanagement/project/manageuser/"+this.metadata._id, {data: [data]}).then(dt => {
          if(dt.data.status === "success"){
            this.associateuserdialog = false;
            this.deleteuserdialog = false;
            if(type === "associate")this.$store.commit("sbSuccess", "User Associated");
            else if(type === "move") this.$store.commit("sbSuccess", "User Changed");
            else if(type === "remove") this.$store.commit("sbSuccess", "User Removed");
            successflag = true;
          }else throw dt.data.message || "Error changing status";
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.loading = false;
          this.deleteuserloading = false;
          this.associateuserloading = false;
          if(successflag) this.refreshData();
        });
      },
      replaceUserDialog(uid, type) {
        this.replaceuserdata = {type: type};
        this.replaceuserlist = [];
        this.replaceuserid = uid;
        for (const k in this.formdata.users) {
          if (Object.hasOwnProperty.call(this.formdata.users, k)) {
            const el = this.formdata.users[k];
            if(el._id !== uid) this.replaceuserlist.push(el);
          }
        }
        this.replaceuserdialog = true;
      },
      replaceUser() {
        let type = this.replaceuserdata.type;
        if(["manager", "general"].indexOf(type) === -1) this.$store.commit("sbError", "Invalid Type");
        else{
          let fromuserid = this.replaceuserid;
          let touserid = this.replaceuserdata.userid;
          this.replaceuserloading = true;
          let data = {fromuserid: fromuserid, touserid: touserid, type: type};
          this.axios.post("/v2/fluxmanagement/project/replaceuser/"+this.metadata._id, {data: [data]}).then(dt => {
            if(dt.data.status === "success"){
              this.userlist = [];
              this.managerlist = [];
              this.refreshData();
              this.replaceuserdialog = false;
              this.$store.commit("sbSuccess", "User Replaced");
            }else throw dt.data.message || "Error changing status";
          }).catch(err => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          }).finally(() => {
            this.replaceuserloading = false;
          });
        }
      },
    },
    computed: {
      finaluserlist(){
        let nl = [];
        for (const i of this.managerlist) {
          let ud = {...this.userdata[i]};
          ud.type = "manager";
          nl.push(ud);
        }
        for (const i of this.userlist) {
          let ud = {...this.userdata[i]};
          ud.type = "general";
          nl.push(ud);
        }
        return nl;
      }
    },
    watch: {
    }
  }
  </script>
  